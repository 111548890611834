@font-face {
    font-family: 'Urbanist';
    src: url('Urbanist-Regular.eot');
    src: url('Urbanist-Regular.eot?#iefix') format('embedded-opentype'),
        url('Urbanist-Regular.woff2') format('woff2'),
        url('Urbanist-Regular.woff') format('woff'),
        url('Urbanist-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

